
import React from "react";
import { render } from "react-dom";
import Footer from "../../components/homeCompany/footer/Footer";
import Header from "../../components/homeCompany/header/Header";
// ./src/js/app.js
import { BrowserRouter as Router } from 'react-router-dom';
import HomeCompany from '../../components/homeCompany/HomeCompany';
import {createRoot} from "react-dom/client";
import  "../../styles/homepage/homepage.scss";



// render(<Router><HomePage /></Router>, document.getElementById('rootHome'));

const rootHome = document.getElementById('rootHome');

const userRole = rootHome.dataset.userRole

//Passage à la syntaxe REACT 18
const root = createRoot(rootHome); // createRoot(container!) if you use TypeScript
// root.render(<Router><HomePage /></Router>);
 root.render(<Router><HomeCompany userRole={userRole} /></Router>);
